import { useNavigate } from "react-router-dom";
import {
  Box,
  Chip,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  // Stack,
  // Switch,
  TextField,
  ThemeProvider,
  Typography,
  Stack,
  Checkbox,
  createTheme,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";

// import {
//   Button,
//   Card,
//   CardContent,
//   CardMedia,
//   IconButton,
// } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";

import { LoadScript, Autocomplete } from "@react-google-maps/api"; // Add this line
import { UploadButton } from "../../CustomStyles/Styles";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DetailCard from "./DetailCard";
import {
  aws_url,
  doctorDetailsGet,
  adminDepartmentgetAll,
  doctorHospitalsDelete,
  doctorHospitalsPost,
  doctorHospitalsUpdate,
} from "../../Service/Services";
import { toast } from "react-hot-toast";
import ImageUpload from "./ImageUpload";
import MultiImageUpload from "./MultiImageUpload";

const DoctorHospital = () => {

  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#133680",
      },
      secondary: {
        main: "#f50057",
      },
      text: {
        primary: "#000000",
      },
    },
    typography: {
      fontFamily: "Montserrat",
    },
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

//  const specialitiesData = [
//    "General Practice",
//    "Neurology",
//    "Orthopedics",
//    "Dermatology",
//    "Emergency Medicine",
//    "Obstetrics and Gynecology",
//    "Ophthalmology",
//    "Pediatrics",
//    "Psychiatry",
//    "Pulmonology",
//    "Urology",
//    "Oncology",
//    "Dentistry",
//    "Veterinary Medicine",
//    "Nutrition and Dietetics",
//    "Gastroenterology",
//    "Hepatology",
//    "Nephrology",
//    "Otolaryngology",
//  ];

const [specialitiesData, setSpecialitiesData] = useState([]);

  // const [newhospital, setNewhospital] = useState({
  //   hospitalName: "",
  //   location: {
  //     "type": "Point",
  //     "coordinates": [
  //         0,
  //         0
  //     ]
  //   },
  //   speciality: "",
  //   specialities: [],
  //   pricestarts: 0,
  //   treated: 0,
  //   branches: 0,
  //   noofbeds: 0,
  //   noofdoctors: 0,
  //   about: "",
  //   address: "",
  //   hospitalLocation: "",
  //   hospitalprofileurl: "",
  //   currentlyworking: false,
  //   gallery: [],
  //   establishedyear: "",
  //   doctorid: "",
  // });

  const [newhospital, setNewhospital] = useState({
    hospitalName: "",
    location: {
      type: "Point",
      coordinates: [0, 0],
    },
    speciality: "",
    specialities: [],
    pricestarts: 0,
    treated: 0,
    branches: 0,
    noofbeds: 0,
    noofdoctors: 0,
    about: "",
    address1: "",
    area: "",
    hospitalLocation: "",
    hospitalprofileurl: "",
    currentlyworking: false,
    gallery: [],
    establishedyear: "",
    doctorid: "",
    type_hospital:"",
  });
  const autocompleteRef = useRef(null);

  const [doctorid, setDoctorid] = useState("");
  const [uploadedImage, setUploadImage] = useState({ galleryimage: "" });
  const [hospital, setHospital] = useState([]);

  const [edit, setEdit] = useState(""); 
  // const currentYear = new Date().getFullYear();

  // const [text, setText] = useState("");
  // const [image, setImage] = useState(null);
  // const [items, setItems] = useState([]);
  // const [isEditing, setIsEditing] = useState(false);
  // const [currentItemIndex, setCurrentItemIndex] = useState(null);



  const Departmentget = async () => {
    try {
      const response = await adminDepartmentgetAll();
      const departmentData = response.data?.Data;
      // console.log(departmentData);
      setSpecialitiesData(departmentData);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error("Failed to get department.");
      }
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "An error occurred."
      );
      console.error("Error while fetching departments:", error);
    }
  };

  // const handleTextChange = (e) => setText(e.target.value);

  // Handle image file upload
  // const handleImageChange = (e) => setImage(e.target.files[0]);

  // Add or update item
  // const handleAddOrUpdateItem = () => {
  //   if (!text || !image) {
  //     alert("Please enter text and select an image.");
  //     return;
  //   }

  //   if (isEditing) {
  //     const updatedItems = items.map((item, index) =>
  //       index === currentItemIndex ? { text, image } : item
  //     );
  //     setItems(updatedItems);
  //     setIsEditing(false);
  //     setCurrentItemIndex(null);
  //   } else {
  //     setItems([...items, { text, image }]);
  //   }

  //   // Reset the form
  //   setText("");
  //   setImage(null);
  // };

  // Edit an item
  // const handleEdit = (index) => {
  //   setText(items[index].text);
  //   setImage(items[index].image);
  //   setIsEditing(true);
  //   setCurrentItemIndex(index);
  // };

  // Delete an item
  // const handleDelete = (index) => {
  //   const filteredItems = items.filter((_, i) => i !== index);
  //   setItems(filteredItems);
  // };

  // const forHospitalChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   const newValue = type === "checkbox" ? checked : value;
  //   if (name === "singlespecialities") {
  //     setNewhospital({ ...newhospital, specialities: [newValue] });
  //   } else {
  //     setNewhospital({ ...newhospital, [name]: newValue });
  //   }
  // };

  const forHospitalChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    if (name === "singlespecialities") {
      setNewhospital({ ...newhospital, specialities: [newValue] });
    } else {
      setNewhospital({ ...newhospital, [name]: newValue });
    }
  };

  // const handleEstdYearChange = (date) => {
  //   setNewhospital((prevstate) => ({
  //     ...prevstate,
  //     establishedyear: date.getFullYear(),
  //   }));
  // };

  const forEditHospital = (index) => {
    if (edit !== "") {
      toast.error("please update the existing hospital");
      return;
    }

    const hospitalToEdit = hospital[index];
    setEdit(hospitalToEdit._id);
    const newFilter = hospital.filter((_, xd) => xd !== index);
    setHospital(newFilter);
    setNewhospital({ ...hospitalToEdit });
  };

  // const forSubmit = async () => {
  //   try {
  //     if (
  //       newhospital.hospitalName &&
  //       newhospital.speciality &&
  //       newhospital.location &&
  //       newhospital.pricestarts &&
  //       newhospital.treated &&
  //       newhospital.branches &&
  //       newhospital.noofbeds &&
  //       newhospital.noofdoctors &&
  //       newhospital.about &&
  //       newhospital.pricestarts &&
  //       newhospital.hospitalLocation &&
  //       newhospital.hospitalprofileurl &&
  //       newhospital.currentlyworking
  //     ) {
  //       setNewhospital(newhospital);
  //       if (edit) {
  //         handleHospitalUpdate();
  //       } else {
  //         await doctorHospitalsPost({ ...newhospital, owner: doctorid });
  //       }

  //       setNewhospital({
  //         hospitalName: "",
  //         hospitalLocation: "",
  //         speciality: "",
  //         pricestarts: 0,
  //         treated: 0,
  //         branches: 0,
  //         noofbeds: 0,
  //         noofdoctors: 0,
  //         about: "",
  //         address: "",
  //         hospitalprofileurl: "",
  //         currentlyworking: false,
  //       });
  //     }
  //   } catch (error) {
  //     toast.error("Error occured!");
  //   }
  // };

  const handlePlaceSelect = (place) => {
    const addressComponents = place.address_components;

    const streetNumber =
      addressComponents.find((comp) => comp.types.includes("street_number"))
        ?.long_name || "";
    const route =
      addressComponents.find((comp) => comp.types.includes("route"))
        ?.long_name || "";
    const city =
      addressComponents.find((comp) => comp.types.includes("locality"))
        ?.long_name || "";
    const state =
      addressComponents.find((comp) =>
        comp.types.includes("administrative_area_level_1")
      )?.long_name || "";
    const zipcode =
      addressComponents.find((comp) => comp.types.includes("postal_code"))
        ?.long_name || "";
    const country =
      addressComponents.find((comp) => comp.types.includes("country"))
        ?.long_name || "";

    setNewhospital({
      ...newhospital,
      address1: `${streetNumber} ${route}`.trim(),
      city,
      state,
      zipcode,
      country,
    });
  };

  // const forSubmit = async () => {
  //   try {
  //     if (
  //       newhospital.hospitalName &&
  //       newhospital.speciality &&
  //       newhospital.location &&
  //       newhospital.pricestarts &&
  //       newhospital.treated &&
  //       newhospital.branches &&
  //       newhospital.noofbeds &&
  //       newhospital.noofdoctors &&
  //       newhospital.about &&
  //       newhospital.address1 &&  // Include new address fields
  //       newhospital.city &&
  //       newhospital.state &&
  //       newhospital.zipcode &&
  //       newhospital.country &&
  //       newhospital.hospitalLocation &&
  //       newhospital.hospitalprofileurl &&
  //       newhospital.currentlyworking
  //     ) {
  //       setNewhospital(newhospital);
  //       if (edit) {
  //         handleHospitalUpdate();
  //       } else {
  //         await doctorHospitalsPost({ ...newhospital, owner: doctorid });
  //       }

  //       setNewhospital({
  //         hospitalName: "",
  //         hospitalLocation: "",
  //         speciality: "",
  //         pricestarts: 0,
  //         treated: 0,
  //         branches: 0,
  //         noofbeds: 0,
  //         noofdoctors: 0,
  //         about: "",
  //         address1: "",  // Reset new address fields
  //         address2: "",
  //         city: "",
  //         state: "",
  //         zipcode: "",
  //         country: "",
  //         hospitalprofileurl: "",
  //         currentlyworking: false,
  //       });
  //     }
  //   } catch (error) {
  //     toast.error("Error occured!");
  //   }
  // };

  const forSubmit = async () => {
    try {
      // Check all required fields are filled
      if (
        newhospital.hospitalName
        // newhospital.speciality &&
        // newhospital.location &&
        // newhospital.location.coordinates &&  // Ensure coordinates are included
        // newhospital.pricestarts &&
        // newhospital.treated &&
        // newhospital.branches &&
        // newhospital.noofbeds &&
        // newhospital.noofdoctors &&
        // newhospital.about &&
        // newhospital.address1 &&
        // newhospital.city &&
        // newhospital.state &&
        // newhospital.zipcode &&
        // newhospital.country &&
        // newhospital.hospitalLocation &&
        // newhospital.hospitalprofileurl &&
        // typeof newhospital.currentlyworking === "boolean"
      ) {
        // Structure the address data to match backend schema
        const formattedAddress = {
          streetNumber: newhospital.address1.split(" ")[0] || "",
          streetName: newhospital.address1.split(" ").slice(1).join(" ") || "",
          city: newhospital.city,
          state: newhospital.state,
          zipcode: newhospital.zipcode,
          country: newhospital.country,
          formattedAddress: `${newhospital.address1}, ${newhospital.city}, ${newhospital.state}, ${newhospital.zipcode}, ${newhospital.country}`,
        };


        
        const formattedHospitalData = {
          hospitalName: newhospital.hospitalName,
          speciality: newhospital.speciality,
          // location: {
          //   type: "Point",
          //   coordinates: newhospital.location.coordinates, // Ensure it's in [longitude, latitude] format
          // },
          specialities: newhospital.specialities,
          // pricestarts: newhospital.pricestarts,
          // treated: newhospital.treated,
          // branches: newhospital.branches,
          // noofbeds: newhospital.noofbeds,
          // noofdoctors: newhospital.noofdoctors,
          about: newhospital.about,
          address: formattedAddress, // Embedded address
          address1: newhospital.address1, // Include address1 here
          city: newhospital.city,
          area: newhospital.area,
          state: newhospital.state,
          zipcode: newhospital.zipcode,
          country: newhospital.country,
          // hospitalLocation: newhospital.hospitalLocation,
          hospitalprofileurl: newhospital.hospitalprofileurl,
          // currentlyworking: newhospital.currentlyworking,
          owner: doctorid,
          gallery: newhospital.gallery || [],
          establishedyear: newhospital.establishedyear || null,
          type_hospital:newhospital.type_hospital || null,
        };



        // Log the data to confirm structure before submission
        console.log("Formatted data before submission:", formattedHospitalData);

        // Call appropriate function based on edit state
        if (edit) {
          handleHospitalUpdate(formattedHospitalData);
        } else {
          await doctorHospitalsPost(formattedHospitalData);
        }

        // Reset the state after submission
        setNewhospital({
          hospitalName: "",
          hospitalLocation: "",
          speciality: "",
          location: { type: "Point", coordinates: [] },
          pricestarts: 0,
          treated: 0,
          branches: 0,
          noofbeds: 0,
          noofdoctors: 0,
          about: "",
          address1: "",
          area: "",
          hospitalprofileurl: "",
          currentlyworking: false,
          gallery: [],
          establishedyear: "",
          type_hospital: ""
        });
        window.location.reload();
      } else {
        toast.error("Please fill all required fields.");
      }
    } catch (error) {
      console.error("Error in forSubmit:", error);
      toast.error("Error occurred!");
    }
  };

  const handleHospitalUpdate = async () => {
    const responseJson = await doctorHospitalsUpdate(
      { ...newhospital, owner: doctorid },
      edit
    );
    if (responseJson?.data.status) {
      toast.success(responseJson?.data.message);
    } else {
      toast.error(responseJson?.data.message);
    }
    setEdit("");
    forGetHospitals();
  };

  const navigate = useNavigate();

  const forGetHospitals = async () => {
    const response = await doctorDetailsGet();
    if (!response.data.data.verified) navigate("/doctor/alert");

    if (response.data?.data.hospitals) {
      setHospital(response.data?.data.hospitals);
      console.log(response.data?.data.hospitals);
      
      setDoctorid(response?.data.data?._id);
    }
  };

  const handleQuillChange = (value) => {
    setNewhospital((prev) => ({ ...prev, about: value }));
  };

  const forDeleteHospital = async (id) => {
    // setHospital(hospital.filter((_, xd) => xd !== index));
    const response = await doctorHospitalsDelete(id);
    if (response?.data.status) {
      toast.success(response?.data.message);
      forGetHospitals();
    } else {
      toast.error(response?.data.message);
    }
  };
  useEffect(() => {
    setNewhospital((prevstate) => ({
      ...prevstate,
      gallery: [...prevstate.gallery, uploadedImage.galleryimage],
    }));
  }, [uploadedImage.galleryimage]);

  useEffect(() => {
    forGetHospitals();
  }, []);

  useEffect(()=>{
    Departmentget();
  },[]);

  const updateCheckbox = (event) => {
    const { name } = event.target;
    setNewhospital({ ...newhospital, type_hospital: name });
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Container>
          <Box component="form">
          
            <Box component={"div"} className="hospital" sx={{ marginY: 1.5 }}>

              <Typography variant="h5" component={"h5"} className="mb-2">
                Add New
              </Typography>
              <Grid item xs={12}>
                <Stack
                  direction={{ xs: "column", sm: "row" }} // Stack items in a column on smaller screens and in a row on larger screens
                  spacing={1}
                  alignItems="center"
                  sx={{
                    border: "1px solid #9E9FA8",
                    padding: 0.28,
                    borderRadius: 1,
                    justifyContent: "space-between",
                    width: "100%", // Use 100% to make the Stack responsive
                  }}
                >
                  

<div
  style={{
    width: "100%",
    maxWidth: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  }}
>
  <Checkbox
    name="Hospital"
    checked={newhospital.type_hospital === "Hospital"}
    onChange={updateCheckbox}
    inputProps={{ "aria-label": "controlled" }}
  />
  <Typography>Hospital</Typography>
</div>

<div
  style={{
    width: "100%",
    maxWidth: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  }}
>
  <Checkbox
    name="Clinic"
    checked={newhospital.type_hospital === "Clinic"}
    onChange={updateCheckbox}
    inputProps={{ "aria-label": "controlled" }}
  />
  <Typography>Clinic</Typography>
</div>

<div
  style={{
    width: "100%",
    maxWidth: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  }}
>
  <Checkbox
    name="Center"
    checked={newhospital.type_hospital === "Center"}
    onChange={updateCheckbox}
    inputProps={{ "aria-label": "controlled" }}
  />
  <Typography>Center</Typography>
</div>

                </Stack>
              </Grid>
              <Grid item xs={12} mt={4} sm={6} md={6} lg={5}>
                <Box component={"div"} className="pb-2">
                  <InputLabel>Main Hospital Image / Logo </InputLabel>
                  <ImageUpload
                    setForm={setNewhospital}
                    fieldname={"hospitalprofileurl"}
                    imageurl={
                      newhospital.hospitalprofileurl 
                        ? `${aws_url}/${newhospital.hospitalprofileurl}`
                        : "https://images.healthmudraa.com/1731687545384-download.png"
                    }
                  />
                </Box>
              </Grid>

              <Grid container spacing={2}>
              {/* <Grid item xs={12} sm={6} md={6} lg={5}>
                  <label
                    htmlFor="type_hospital"
                    style={{
                      paddingBottom: "5px",
                      fontSize: "18px",
                      paddingTop: "10px",
                    }}
                  >
                    Type of Hospital 
                  </label>
                  <FormControl fullWidth>
                    <Select
                      labelId="type-hospital-select-id"
                      sx={{ height: "2.4em" }}
                      value={newhospital.type_hospital}
                      onChange={forHospitalChange}
                      name="type_hospital"
                    >
                      
                      <MenuItem value={"Hospital"}>
                        Hospital 
                      </MenuItem>
                      <MenuItem value={"Center"}>
                      Center
                      </MenuItem>
                      <MenuItem value={"Clinic"}>
                      Clinic 
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item xs={12} sm={12} md={12} lg={10}>
                  <label
                    htmlFor="firstname"
                    style={{
                      paddingBottom: "5px",
                      fontSize: "18px",
                      paddingTop: "10px",
                    }}
                  >
                    Name Of The Hospital/Clinic
                  </label>
                  <TextField
                    required
                    fullWidth
                    value={newhospital.hospitalName}
                    autoComplete="off"
                    name="hospitalName"
                    type="string"
                    placeholder="Hostpital Name*"
                    onChange={forHospitalChange}
                    InputProps={{
                      sx: {
                        height: "2.4em",
                      },
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={6} lg={5}>
                <label htmlFor="firstname" style={{ paddingBottom: "5px", fontSize: "18px", paddingTop:"10px" }}>Location</label>
                  <TextField
                    required
                    fullWidth
                    value={newhospital.hospitalLocation}
                    autoComplete="off"
                    name="hospitalLocation"
                    type="string"
                    placeholder="Eg. "
                    onChange={forHospitalChange}
                    InputProps={{
                      sx: {
                        height: "2.4em",
                      },
                    }}
                  />
                </Grid> */}

                {/* <Grid item xs={12} sm={6} md={6} lg={5}>
                <label htmlFor="firstname" style={{ paddingBottom: "5px", fontSize: "18px", paddingTop:"10px" }}>Patients Treated</label>
                  <TextField
                    required
                    fullWidth
                    value={newhospital.treated}
                    autoComplete="off"
                    name="treated"
                    type="number"
                    placeholder="Patients Treated*"
                    onChange={forHospitalChange}
                    InputProps={{
                      sx: {
                        height: "2.4em",
                      },
                    }}
                  />
                </Grid> */}

                {/* <Grid item xs={12} sm={6} md={6} lg={5}>
                <label htmlFor="firstname" style={{ paddingBottom: "5px", fontSize: "18px", paddingTop:"10px" }}>Consultation Price</label>
                  <TextField
                    required
                    fullWidth
                    value={newhospital.pricestarts}
                    autoComplete="off"
                    name="pricestarts"
                    type="number"
                    placeholder="Consultation Price*"
                    onChange={forHospitalChange}
                    InputProps={{
                      sx: {
                        height: "2.4em",
                      },
                    }}
                  />
                </Grid> */}

                <Grid item xs={12} sm={6} md={6} lg={5}>
                  <label
                    htmlFor="firstname"
                    style={{
                      paddingBottom: "5px",
                      fontSize: "18px",
                      paddingTop: "10px",
                    }}
                  >
                    Speciality Type
                  </label>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      sx={{ height: "2.4em" }}
                      value={newhospital.speciality}
                      onChange={forHospitalChange}
                      name="speciality"
                    >
                      <MenuItem disabled value="">
                        Select Speciality
                      </MenuItem>
                      <MenuItem value={"Single-Speciality"}>
                        Single-Speciality
                      </MenuItem>
                      <MenuItem value={"Muti-Speciality"}>
                        Muti-Speciality
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {newhospital.speciality === "Single-Speciality" ? (
                  <Grid item xs={12} sm={6} md={6} lg={10}>
                    <FormControl fullWidth>
                      <Select
                        name="singlespecialities"
                        sx={{
                          height: "2.4em",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={newhospital.specialities[0]}
                        onChange={forHospitalChange}
                      >
                        {specialitiesData.map((option) => {
                          return (
                            <MenuItem key={option._id} value={option.departmentName}>
                              {option.departmentName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} md={6} lg={10}>
                    <FormControl fullWidth>
                      <Select
                        name="specialities"
                        value={
                          newhospital.specialities
                            ? newhospital.specialities
                            : []
                        }
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        sx={{
                          height: `${
                            newhospital.specialities?.length >= 3
                              ? "auto"
                              : "2.4em"
                          }`,
                          fontFamily: "Montserrat",
                        }}
                        multiple
                        displayEmpty
                        onChange={forHospitalChange}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            sx={{
                              fontFamily: "Montserrat",
                            }}
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected && selected.length === 0 ? (
                              <Typography
                                component={"p"}
                                sx={{
                                  color: "#9E9FA8",
                                  fontFamily: "Montserrat",
                                }}
                              >
                                Specialities
                              </Typography>
                            ) : (
                              selected &&
                              selected?.map((value) => (
                                <Chip
                                  sx={{ fontFamily: "Montserrat" }}
                                  key={value}
                                  label={value}
                                />
                              ))
                            )}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem disabled value="">
                          <Typography
                            component={"p"}
                            sx={{
                              fontFamily: "Montserrat",
                              fontWeight: "bold",
                            }}
                          >
                            Specialities
                          </Typography>
                        </MenuItem>
                        {specialitiesData.map((speciality) => (
                          <MenuItem
                            sx={{ fontFamily: "Montserrat" }}
                            key={speciality._id}
                            value={speciality.departmentName}
                          >
                            {speciality.departmentName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {/* <Grid item xs={12} sm={6} md={6} lg={5}>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{
                      border: "1px solid #9E9FA8",
                      padding: 0.28,
                      borderRadius: 1,
                    }}
                  >
                    <Typography className="ms-2" sx={{ fontSize: 14 }}>
                      Currently Working
                    </Typography>
                    <Switch
                      name="currentlyworking"
                      checked={newhospital.currentlyworking}
                      onChange={forHospitalChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography>
                      {newhospital.currentlyworking ||
                      newhospital.currentlyworking
                        ? "Yes"
                        : "No"}
                    </Typography>
                  </Stack>
                </Grid> */}
              </Grid>

              <Box component={"div"} sx={{ mt: 4 }}>
                <Typography variant="h5" component={"h5"} className="mb-2">
                  Hospital Gallery
                </Typography>
                <MultiImageUpload
                  setForm={setUploadImage}
                  fieldname={"galleryimage"}
                  imageurl={""}
                  emptyimage={true}
                />
                <Grid container spacing={1} marginTop={"1rem"}>
                  {newhospital.gallery &&
                    newhospital.gallery.map((image) => {
                      if (image) {
                        return (
                          <Grid item key={image}>
                            <img
                              src={`${aws_url}/${image}`}
                              alt="profile-preview"
                              className="imgpreview"
                            />
                          </Grid>
                        );
                      } else {
                        return null;
                      }
                    })}
                </Grid>
              </Box>

              <Box component={"div"} sx={{ mt: 4 }}>
                {/* <Typography variant="h5" component={"h5"} className="mb-2" style={{ fontWeight: 'bold' }}>
                  Infrastructure
                </Typography> */}

                {/* <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={5}>
                  <label htmlFor="firstname" style={{ paddingBottom: "5px", fontSize: "18px", paddingTop:"10px" }}>No. Of Branches</label>
                    <TextField
                      required
                      fullWidth
                      value={newhospital.branches}
                      autoComplete="off"
                      name="branches"
                      type="number"
                      placeholder="No of Branches*"
                      onChange={forHospitalChange}
                      InputProps={{
                        sx: {
                          height: "2.4em",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={5}>
                  <label htmlFor="firstname" style={{ paddingBottom: "5px", fontSize: "18px", paddingTop:"10px" }}>No. Of Beds</label>
                    <TextField
                      required
                      fullWidth
                      value={newhospital.noofbeds}
                      autoComplete="off"
                      name="noofbeds"
                      type="number"
                      placeholder="No of Beds*"
                      onChange={forHospitalChange}
                      InputProps={{
                        sx: {
                          height: "2.4em",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={5}>
                  <label htmlFor="firstname" style={{ paddingBottom: "5px", fontSize: "18px", paddingTop:"10px" }}>No. Of Doctors</label>
                    <TextField
                      required
                      fullWidth
                      value={newhospital.noofdoctors}
                      autoComplete="off"
                      name="noofdoctors"
                      type="number"
                      placeholder="No of Doctors*"
                      onChange={forHospitalChange}
                      InputProps={{
                        sx: {
                          height: "2.4em",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={5}>
                    <label htmlFor="firstname" style={{ paddingBottom: "5px", fontSize: "18px", paddingTop:"10px" }}>Established Year</label>
                    <div>
                    <DatePicker
                      selected={
                        newhospital.establishedyear
                          ? new Date(newhospital.establishedyear, 0, 1)
                          : null
                      }
                      onChange={handleEstdYearChange}
                      dateFormat="yyyy"
                      showYearPicker
                      maxDate={new Date(currentYear, 11, 31)}
                      placeholderText="To Year"
                      className="form-control"
                    />
                    </div>
                  </Grid>

                  {/* <Grid item xs={12} sm={12} md={12} lg={10}>
                  <label htmlFor="firstname" style={{ paddingBottom: "5px", fontSize: "18px", paddingTop:"10px" }}>About</label>
                    <TextField
                      required
                      fullWidth
                      value={newhospital.about}
                      id="multiline-text"
                      autoComplete="off"
                      name="about"
                      type="number"
                      placeholder="Eg. "
                      multiline
                      rows={4}
                      onChange={forHospitalChange}
                    />
                  </Grid> */}

                {/* About Field using ReactQuill */}
                <Grid item xs={12} sm={12} md={12} lg={10}>
                  <label
                    htmlFor="about"
                    style={{
                      paddingBottom: "5px",
                      fontSize: "18px",
                      paddingTop: "10px",
                    }}
                  >
                    About
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={newhospital.about}
                    onChange={handleQuillChange}
                    placeholder="Describe here..."
                    style={{ height: "150px", marginBottom: "20px" }}
                  />
                </Grid>

                {/* <Grid item xs={12} sm={12} md={12} lg={10}>
                  <label htmlFor="firstname" style={{ paddingBottom: "5px", fontSize: "18px", paddingTop:"10px" }}>Address</label>
                    <TextField
                      required
                      fullWidth
                      value={newhospital.address}
                      id="multiline-text"
                      autoComplete="off"
                      name="address"
                      placeholder="Eg. "
                      multiline
                      rows={4}
                      onChange={forHospitalChange}
                    />
                  </Grid> */}

                <LoadScript
                  googleMapsApiKey="AIzaSyCCTLB2y0MvFCAPI7RGgHPaEo9EYh1VkPU"
                  libraries={["places"]}
                >
                  <Grid container spacing={2}>
                    {/* Address Line 1 with Autocomplete */}
                    <Grid item xs={12} sm={12} md={12} lg={10}>
                      <label
                        htmlFor="address1"
                        style={{
                          paddingBottom: "5px",
                          fontSize: "18px",
                          paddingTop: "30px",
                        }}
                      >
                        Address Line 1
                      </label>
                      <Autocomplete
                        onLoad={(autocomplete) =>
                          (autocompleteRef.current = autocomplete)
                        }
                        onPlaceChanged={() => {
                          const place = autocompleteRef.current.getPlace();
                          handlePlaceSelect(place);
                        }}
                      >
                        <TextField
                          required
                          fullWidth
                          value={newhospital.address1}
                          name="address1"
                          placeholder="Address Line 1"
                          onChange={(e) =>
                            setNewhospital({
                              ...newhospital,
                              address1: e.target.value,
                            })
                          }
                        />
                      </Autocomplete>
                    </Grid>
                    {/* Area Field */}
                    <Grid item xs={12} sm={12} md={12} lg={10}>
                      <TextField
                        required
                        fullWidth
                        value={newhospital.area}
                        name="area"
                        placeholder="Area "
                        onChange={(e) =>
                          setNewhospital({
                            ...newhospital,
                            area: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    {/* Additional address fields */}
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                      <label
                        htmlFor="city"
                        style={{
                          paddingBottom: "5px",
                          fontSize: "18px",
                          paddingTop: "10px",
                        }}
                      >
                        City
                      </label>
                      <TextField
                        required
                        fullWidth
                        value={newhospital.city}
                        name="city"
                        placeholder="City"
                        onChange={(e) =>
                          setNewhospital({
                            ...newhospital,
                            city: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={5}>
                      <label
                        htmlFor="state"
                        style={{
                          paddingBottom: "5px",
                          fontSize: "18px",
                          paddingTop: "10px",
                        }}
                      >
                        State
                      </label>
                      <TextField
                        required
                        fullWidth
                        value={newhospital.state}
                        name="state"
                        placeholder="State"
                        onChange={(e) =>
                          setNewhospital({
                            ...newhospital,
                            state: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={5}>
                      <label
                        htmlFor="zipcode"
                        style={{
                          paddingBottom: "5px",
                          fontSize: "18px",
                          paddingTop: "10px",
                        }}
                      >
                        Zip Code
                      </label>
                      <TextField
                        required
                        fullWidth
                        value={newhospital.zipcode}
                        name="zipcode"
                        placeholder="Zip Code"
                        onChange={(e) =>
                          setNewhospital({
                            ...newhospital,
                            zipcode: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={5}>
                      <label
                        htmlFor="country"
                        style={{
                          paddingBottom: "5px",
                          fontSize: "18px",
                          paddingTop: "10px",
                        }}
                      >
                        Country
                      </label>
                      <TextField
                        required
                        fullWidth
                        value={newhospital.country}
                        name="country"
                        placeholder="Country"
                        onChange={(e) =>
                          setNewhospital({
                            ...newhospital,
                            country: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </LoadScript>

                {/* </Grid> */}
                {/* try  */}
              </Box>
            </Box>
          </Box>
          <div
            className="d-flex justify-content-between mt-3 "
            style={{
              paddingTop: "5px",
              paddingBottom: "20px",
              position: "absolute",
              right: "15%",
            }}
          >
            <UploadButton
              className="mt-3 px-4"
              onClick={forSubmit}
              style={{
                fontFamily: "Montserrat",
                backgroundColor: "#133680",
                color: "white",
              }}
            >
              {edit !== "" ? "Update" : "Submit"}
            </UploadButton>
          </div>
          <DetailCard
            DataType={hospital}
            TicketName={"Hospital"}
            DataEditFunction={forEditHospital}
            DataDeleteFunction={forDeleteHospital}
            edit={edit}
          />
        </Container>
      </ThemeProvider>

      {/* <div style={{ padding: "" , maxWidth: "650px"}}>
      <div style={{ marginBottom: "20px", display: "flex",   gap: "10px", alignItems: "center" }}>
        <TextField
          label="Enter text"
          variant="outlined"
          value={text}
          onChange={handleTextChange}
        />
        <Button variant="" component="label">
          Upload Image
          <input type="file" hidden onChange={handleImageChange} />
        </Button>
        <Button variant="contained" color="primary" onClick={handleAddOrUpdateItem}>
          {isEditing ? "Update Item" : "Add Item"}
        </Button>
      </div>

      <div>
        {items.length > 0 ? (
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            {items.map((item, index) => (
              <Card key={index} sx={{ display: "flex", alignItems: "center", padding: "10px" }}>
                <CardMedia
                  component="img"
                  sx={{ width: 60, height: 60, objectFit: "", marginRight: "20px" }}
                  src={URL.createObjectURL(item.image)}
                  alt="Uploaded"
                />
                <CardContent sx={{ flex: 1 }}>
                  <Typography variant="body1">{item.text}</Typography>
                </CardContent>
                <IconButton color="primary" onClick={() => handleEdit(index)}>
                  <EditIcon />
                </IconButton>
                <IconButton color="secondary" onClick={() => handleDelete(index)}>
                  <DeleteIcon />
                </IconButton>
              </Card>
            ))}
          </div>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No items added yet.
          </Typography>
        )}
      </div>
    </div> */}
    </React.Fragment>
  );
};

export default DoctorHospital;
