import React, { useEffect, useState } from "react";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import DoctorTabs from "./DoctorTabs";
import DoctorTabs1 from "./DoctorTabs1";
import { useParams } from "react-router-dom";
import { aws_url, getSpecificDoctor } from "../Service/Services";
import toast from "react-hot-toast";
// import { Flex } from "../styles/CommonStyles";
// import { DoctorBriefDetails, DoctorPic } from "../styles/DoctorStyles";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const DoctorProfile = ({ doctorid, subprofile = false }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const getDoctorDetails = async () => {
    const responseJson = await getSpecificDoctor(subprofile ? doctorid : id);
    if (responseJson.data?.status) {
      setLoading(true);
      setDetails(responseJson.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error("Error while fetching details");
      toast.error(responseJson.data?.message);
    }
  };
  useEffect(() => {
    if (doctorid || id) {
      getDoctorDetails();
    }
  }, [id, doctorid]);

  return (
    <div className="mt-5">
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <div>
        {/* <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={20}
          padding="14px"
          style={{
            borderRadius: 5,
            backgroundColor: "#0A6EBD99",
            margin: 1,
          }}
        >
          <DoctorPic
            src={`${aws_url}/${details.profilepicurl}`}
            alt={details.firstname}
          />

          <DoctorBriefDetails>
            <h1>{details.firstname}</h1>

            <Flex alignItems="center" justifyContent="right">
              <LocationOnIcon className="icon" />{" "}
              <span>{details?.location}</span>
            </Flex>
            {details?.title && <p>{details?.title}</p>}
            <p>
              <span>Consults at:</span>{" "}
              <span>
                {" "}
                {details.hospitals &&
                  details?.hospitals.map((xd) => {
                    return xd.currentlyworking ? xd.hospitalName : "";
                  })}
              </span>
            </p>
          </DoctorBriefDetails>
        </Flex> */}
        <React.Fragment>
      <Container>
        <Box component="form" noValidate sx={{ mt: 3, mb: 2 }}>
          <Grid container spacing={2} key={details._id}>
            {/* Profile Picture */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Profile Picture
              </Typography>
              <img
                src={`${aws_url}/${details.profilepicurl}`}
                alt="Profile"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginTop: "8px",
                }}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                value={details.email}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                value={details.firstname}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                value={details.lastname}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            {/* Mobile */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Mobile"
                value={details.mobile}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            {/* Location */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Location"
                value={details.location}
                InputProps={{ readOnly: true }}
              />
            </Grid>

             {/* Specialization */}
             <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Specialization"
                value={details.specilization}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            {/* Languages */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Languages"
                value={details.languages}
                InputProps={{ readOnly: true }}
              />
            </Grid>



            </Grid>
            </Box>
            </Container>
            </React.Fragment>
        </div>
      )}

      <DoctorTabs1 details={details} loading={loading} />
    </div>
  );
};

export default DoctorProfile;
