import React from "react";
import toast from "react-hot-toast";
import {
    adminUpdateDoctor,
    adminDeleteDoctor,
  } from "../Service/Services";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Typography,
  useMediaQuery,
  Grid,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AlbumIcon from "@mui/icons-material/Album";
import { School, WorkHistory, Badge } from "@mui/icons-material";
import {
  Flex,
  FlexCol,
  FlexColFullWidth,
  Heading3,
} from "../styles/CommonStyles";
import { DetailsCardWrapper } from "../styles/DoctorStyles";
import { aws_url } from "../Service/Services";


const DoctorTabs1 = ({ details, loading }) => {
    const navigate = useNavigate();
   

    const onApprove= async ()=>{
        
        const requestBody = { "verified": true };
        console.log("reqb: "+requestBody);
        console.log("dataid "+details);
        const responseJson = await adminUpdateDoctor(requestBody, details._id);
        
        if (responseJson.data.status) {
            toast.success(responseJson.data.message);
        } else {
            toast.error(responseJson.data.message);
        }
        navigate("/admin/dashboard/verifydoctor");

    }
    
    const onReject= async ()=>{
        const responseJson = await adminDeleteDoctor(details._id);
        if (responseJson.data.status) {
          toast.success(responseJson.data.message);
        } else {
          toast.error(responseJson.data.message);
        }
        navigate("/admin/dashboard/verifydoctor");
    }

    const Registrations = () => {
        return (
          <Flex margin="10px 0">
            {details.registration &&
              details.registration.map((xd, index) => {
                return (
                  <DetailsCardWrapper key={index}>
                    <Badge style={{ color: "#133680", fontSize: 48 }} />
                    <FlexCol gap={5}>
                      <h3 className="hospital">{xd.council}</h3>
                      <h3 className="designation">reg-Id{xd.regno}</h3>
                      <h3 className="dateRange">{xd.year}</h3>
                    </FlexCol>
                  </DetailsCardWrapper>
                );
              })}
          </Flex>
        );
      };
    
    
      const TreatmentsOffered = () => {
        const forBelow575px = useMediaQuery("(max-width:575px)");
    
        return (
          <div className="container  mt-4 mb-4">
            <div className="row d-flex">
              {details.treatments &&
                details.treatments.map((xd) => {
                  return (
                    <div
                      key={xd._id}
                      className="col-lg-6 col-md-12 col-sm-12 col-12"
                    >
                      <Card
                        sx={{
                          display: "flex",
                          alignContent: "baseline",
                          flexDirection: forBelow575px ? "column" : "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "12px",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <AlbumIcon style={{ color: "#133680" }} />
                          <Box
                            sx={{
                              marginLeft: forBelow575px ? 0 : 2,
                            }}
                          >
                            <Typography variant="h8">{xd.package}</Typography>
                            <Typography variant="subtitle1">
                              Starting from ₹{xd.minprice}
                            </Typography>
                          </Box>
                        </Box>
                        <Button
                          size="medium"
                          onClick={() => navigate(`/treatments/${xd._id}`)}
                          sx={{
                            fontWeight: "bold",
                            border: "1px solid #64CCC5",
                            height: 20,
                            color: "#64CCC5",
                            textTransform: "lowercase",
                          }}
                        >
                          View
                        </Button>
                      </Card>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      };
    
    
      const Hospitals = () => {
        return (
          <Flex margin="10px 0" className="scrollbar overflow-x-scroll gap-x-7">
            {details.hospitals &&
              details.hospitals.map((xd) => {
                return (
                  <div
                    key={xd._id}
                    className="col-lg-4 col-md-6 col-sm-12 col-10 mb-4"
                  >
                    <Card sx={{ borderRadius: 2 }}>
                      <CardMedia
                        sx={{ height: 200, objectFit: "cover" }}
                        component="img"
                        image={`${aws_url}/${xd.hospitalprofileurl}`}
                      />
                      <CardContent sx={{ p: 2, mb: 0 }}>
                        <Box sx={{ mb: 1 }}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bold",
    
                              fontSize: 16,
                            }}
                          >
                            {xd.hospitalName}{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                                backgroundColor: xd.currentlyworking
                                  ? "#133680"
                                  : "#FF2E63",
                                color: "#FFFF",
                                fontSize: 9.2,
                                borderRadius: 8,
                                padding: 1.2,
                              }}
                            >
                              {xd.currentlyworking ? "Working" : "Notworking"}
                            </span>
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            {xd.hospitalLocation}
                          </Typography>
                        </Box>
                        <Divider
                          variant="middle"
                          style={{ backgroundColor: "#001C30" }}
                        />
                        <Typography
                          variant="body1"
                          color="#000000"
                          sx={{
                            mb: 1.2,
    
                            fontWeight: "bold",
                          }}
                        >
                          Branches: {xd.branches}
                        </Typography>
                        <Divider
                          variant="middle"
                          style={{ backgroundColor: "#001C30" }}
                        />
                        <Box
                          component={"h6"}
                          className="d-flex justify-content-between"
                        >
                          <Box>Beds: {xd.noofbeds}</Box>
                          <Box>Doctors: {xd.noofdoctors}</Box>
                        </Box>
                      </CardContent>
                      <CardActions sx={{ justifyContent: "center" }}>
                        <Button
                          onClick={() => navigate(`/hospital/${xd._id}`)}
                          size="small"
                          sx={{
                            border: "1px solid #64CCC5",
                            width: 220,
                            height: 28,
                            color: "#64CCC5",
                            borderRadius: 14,
                            marginBottom: 1.4,
                            textTransform: "inherit",
    
                            fontWeight: "bold",
                          }}
                        >
                          View
                        </Button>
                      </CardActions>
                    </Card>
                  </div>
                );
              })}
          </Flex>
        );
      };
    
      const ExperiencesandStudies = ({ merged = false }) => {
        return (
          <FlexColFullWidth>
            <FlexCol>
              {details?.experiences.length > 0 && (
                 <div>
                <Heading3>
                  <Typography variant="h5" className="h2">
                    Experience
                  </Typography>
                </Heading3>
                <DetailsCardWrapper >
                  <WorkHistory style={{ color: "#133680", fontSize: 48 }} />
                  <FlexCol gap={5}>
                    <h3 className="hospital">
                      {details.experiences[0].hosptalname + ", " + details.experiences[0].location}
                    </h3>
                    <h3 className="designation"> {details.experiences[0].desigination}</h3>
                    <h3 className="dateRange">
                      {new Date(details.experiences[0].startdate).toLocaleDateString() +
                        " - " +
                        new Date(details.experiences[0].enddate).toLocaleDateString()}
                    </h3>
                    {details.experiences[0].experienceurl && (
                        <CardActions sx={{ justifyContent: "center" }}>
                            <a
                           href={`${aws_url}/${details.experiences[0].experienceurl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                            >
                        <Button
                          size="small"
                          sx={{
                            border: "1px solid #64CCC5",
                            width: 220,
                            height: 28,
                            color: "#64CCC5",
                            borderRadius: 14,
                            marginBottom: 1.4,
                            textTransform: "inherit",
    
                            fontWeight: "bold",
                          }}
                        >
                          View
                        </Button>
                        </a>
                      </CardActions>
                    )}
                  </FlexCol>
                </DetailsCardWrapper>
                </div>
              )}
            </FlexCol>
            <FlexCol>
              {details?.qualifications.length > 0 && (
                <div>
                <Heading3>
                  <Typography variant="h5" className="h2">
                    Studies
                  </Typography>
                </Heading3>
                <DetailsCardWrapper >
                <School style={{ color: "#133680", fontSize: 48 }} />
                <FlexCol gap={5}>
                  <h3 className="hospital">
                    {details.qualifications[0].collegeName + ", " + details.qualifications[0].location}
                  </h3>
                  <h3 className="designation"> {details.qualifications[0].degree}</h3>
                  <h3 className="dateRange">
                    {details.qualifications[0].fromYear + " - " + details.qualifications[0].toYear}
                  </h3>
                  {details.qualifications[0].certificateurl && (
                        <CardActions sx={{ justifyContent: "center" }}>
                            <a
                            href={`${aws_url}/${details.qualifications[0].certificateurl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                            >
                        <Button
                          size="small"
                          sx={{
                            border: "1px solid #64CCC5",
                            width: 220,
                            height: 28,
                            color: "#64CCC5",
                            borderRadius: 14,
                            marginBottom: 1.4,
                            textTransform: "inherit",
    
                            fontWeight: "bold",
                          }}
                        >
                          View
                        </Button>
                        </a>
                      </CardActions>
                    )}
                </FlexCol>
              </DetailsCardWrapper>
              </div>
              )}
            </FlexCol>
          </FlexColFullWidth>
        );
      };
    
      const Awards = () => {
        return (
          <FlexCol margin="10px 0">
            {details.awards &&
              details.awards.map((doctor, index) => (
                <div key={index}>
                  <Typography variant="p" component={"h5"}>
                    {doctor.name}
                  </Typography>
                  <ul>
                    <li>{doctor}</li>
                  </ul>
                </div>
              ))}
          </FlexCol>
        );
      };
    




    return (
        <>
        {loading ? (
        <div className="spinner"></div>
      ) : (
        <React.Fragment>
          {/* <Flex>
            <DoctorMission dangerouslySetInnerHTML={{ __html: details?.about }} />
          </Flex>
          <ExperiencesandStudies merged={true} /> */}
        <Container>
        <Box component="form" noValidate sx={{ mt: 3, mb: 2 }}>
          <Grid container spacing={2} key={details._id}></Grid>
          <Heading3>
            <Typography variant="h5" component={"h5"} className="h2">
              Registration
            </Typography>
            <Registrations />
            <Typography variant="h5" component={"h5"} className="h2">
              Treatments
            </Typography>
            <TreatmentsOffered />
            <Typography variant="h5" component={"h5"} className="h2">
              Hospitals
            </Typography>
            <Hospitals />
            <ExperiencesandStudies />
            <Typography variant="h5" component={"h5"} className="h2">
              Awards
            </Typography>
            <Awards />
          </Heading3>
           {/* Action Buttons */}
           <div className="d-flex justify-content-end gap-2 mt-4">
      <Button
        variant="contained"
        color="success"
        onClick={onApprove}
        sx={{ fontFamily: "Montserrat", fontWeight: "bold" }}
      >
        Approve
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={onReject}
        sx={{ fontFamily: "Montserrat", fontWeight: "bold" }}
      >
        Reject
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={()=>{window.close();}}
        sx={{ fontFamily: "Montserrat", fontWeight: "bold" }}
      >
        Close
      </Button>
    </div>
          </Box>
          </Container>
          
        </React.Fragment>)}
        </>
      );

}




export default DoctorTabs1;
