import React, { useState, useEffect } from "react";
import {
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  createTheme,
  ThemeProvider,
  TableHead,
  TextField,
  IconButton,
  MenuItem,
  Popover,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import toast from "react-hot-toast";
import VerifiedIcon from '@mui/icons-material/Verified';
import { createSpecialization,
        deleteSpecialization,
        getAllSpecializations,
        SpecializationUpdate,
 } from "../../Service/Services";

import { FaEye } from "react-icons/fa";

const DoctorSpecialization = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useState(null);
  const [view,setview] = useState(false);
  const [editid,setEditid] = useState(null);
  const [form, setForm] = useState({
    specialization: "",
    doctors: [],
  });
  const [specialitionData, setSpecializationData]  = useState([]);

  const [request,setRequest] = useState([]);

  const handleDelete = async(id)=>{
    
    try {
      const res = await deleteSpecialization(id);
      console.log(res);
    } catch (error) {
      console.log(error);
      
    }
}

const [isRequest,setIsRequest] = useState(false);

const toggleReuquest = () => {
  setIsRequest(!isRequest);
}

  const getSpecializations = async () => {
    try {      
      const data = await getAllSpecializations();
      const temp = data?.data.data
      let arr = []
      let sps = []
      for(let i=0;i<temp.length;i++){
        if(temp[i].status === "Not Verified"){
          arr.push(temp[i]);
        }
        else{
          sps.push(temp[i])
        }
      }
      console.log(arr,sps,temp);
      
      setSpecializationData(sps);
      setRequest(arr);
      
    } catch (error) {
      console.log(error);
    }
  } 

  // Initial fetch of departments
  useEffect(() => {
    getSpecializations();
  }, []);

  // Toggle popup
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const toggleView = ()=>{
    setview(!view);
  }

const forSubmit = async (event) => {
  event.preventDefault(); // Prevent the default form submission

  try {
    let response;
      console.log(form);
      response = await createSpecialization(form); // Pass form object directly

    if (response && response.status === 200) {  
      toast.success(response.data.message);
      setForm({specialization:"",doctors:[]})
      togglePopup(); // Close the popup
    } else {
      toast.error("Failed to save department.");
    }
  } catch (error) {
    toast.error(
      error.response ? error.response.data.message : "An error occurred."
    );
    console.error("Error while submitting:", error);
  }
};

  const forProfileChange = (e)=>{
    const {name,value} = e.target;
    setForm({...setForm,specialization:value})
  }


  // Handle menu actions
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setEditid(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const verifySpecialization = async () => {
    try {
      const data = request.filter((s) => s._id === editid )
      const response = await SpecializationUpdate({...data[0],status:"verified"},editid);
      console.log("Completed");
      
    } catch (error) {
      console.log(error);
      toast.error("Failed to verigy");
    }
  }

  return (
    <React.Fragment>
      <ThemeProvider
        theme={createTheme({
          /* Customize your theme here */
        })}
      >
        <Container>
          {/* Header */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              {!isRequest? "Manage Doctor Speciality" : "New Requests"}
            </Typography>
            <div className="flex gap-4">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setForm({ specialization: "", docors: [] }); // Reset form
                togglePopup();
                getSpecializations();
              }}
            >
              New
            </Button>
            <Button
              variant="contained"
              onClick={toggleReuquest}
            >
              {!isRequest?`Requests(${request.length})`:"Specializations"}
            </Button>
            </div>
          </Stack>

          {/* Department List */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>DOCTOR SPECIALITY</TableCell>
                  <TableCell>ACTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isRequest && specialitionData.map((specialization) => (
                  <TableRow key={specialization._id}>
                    <TableCell>{specialization._id}</TableCell>
                    <TableCell>{specialization.specialization}</TableCell>
                    <TableCell>
                      <IconButton
                        size="large"
                        color="inherit"
                        onClick={(e) => handleOpenMenu(e, specialization._id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {isRequest && request.map((specialization) => (
                  <TableRow key={specialization._id}>
                    <TableCell>{specialization._id}</TableCell>
                    <TableCell>{specialization.specialization}</TableCell>
                    <TableCell>
                      <IconButton
                        size="large"
                        color="inherit"
                        onClick={(e) => handleOpenMenu(e, specialization._id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {showPopup && (
            <form onSubmit={forSubmit}>
              <div className="fixed inset-0 flex items-center justify-center">
                <div className="bg-white shadow-lg rounded-lg p-16">
                   <TextField
                    required
                    fullWidth
                    value={form.specialization}
                    autoComplete="off"
                    name="specialization"
                    onChange={forProfileChange}
                    placeholder="Specialization*"
                  />
                  <div className="flex gap-6">
                  <button className="mt-5 border bg-green-500 rounded p-2 w-1/2" >Add</button>
                  <button  className="mt-5 border bg-red-500 rounded p-2 w-1/2" onClick={togglePopup}>Close</button>
                  </div>
                  </div>
                  </div>
                  </form>)
}

          {/* show doctors view in specialization */}
          {view && (
            <form onSubmit={forSubmit}>
              <div className="fixed inset-0 flex items-center justify-center">
                <div className="bg-white shadow-lg rounded-lg p-16">
                  <div>
                    <Typography className="mt-4 mb-2 md ml-8 mr-16">
                      {specialitionData.filter((s)=>editid===s._id)[0]?.specialization} Doctors
                    </Typography>
                  <ul className="space-y-2 flex flex-col justify-center w-full">
                    {specialitionData.filter((s)=>editid===s._id)[0]?.doctors?.map((spec,i)=>{ return (
                      <li className="p-2 bg-gray-100 rounded-md hover:bg-blue-100 cursor-pointer flex-1 w-full" key={i} >{spec}</li>
                      )})}
                      {specialitionData.filter((s)=>editid===s._id)[0].doctors.length===0?<p className="sm text-red-500">No Doctors Found</p>:null}
                    
                  </ul></div>

                  <div className="flex items-center justify-center">
                    <button
                      className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                      onClick={toggleView}
                      type="button"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
          >
            {isRequest && <MenuItem
              onClick={()=>{
                verifySpecialization();
                handleCloseMenu();
                getSpecializations();
              }}
            >
              <VerifiedIcon />
              Verify
            </MenuItem>}

            {!isRequest && <MenuItem
              onClick={()=>{
                handleCloseMenu();
                toggleView();
              }}
            >
              <FaEye />
              View Doctors
            </MenuItem>}

            <MenuItem
              sx={{ color: "error.main" }}
              onClick={() => {
                handleDelete(editid);
                handleCloseMenu();
                getSpecializations();
              }}
            >
              <DeleteIcon />
              Delete
            </MenuItem>
          </Popover>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default DoctorSpecialization;
