import React, { useState, useEffect } from "react";
import { getRequests,createPackage,getAllPackages } from "../../Service/Services";
import VerifiedIcon from '@mui/icons-material/Verified';
import PackagesNav from "./PackagesNav";
import {
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  createTheme,
  ThemeProvider,
  TableHead,
  TextField,
  IconButton,
  MenuItem,
  Popover,
  Select,
  FormControl,
  InputLabel,
  Paper,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import toast from "react-hot-toast";

import {
  aws_url,
  admintreatmentscreate,
  adminDepartmentgetAll,
  admintreatmentsgetAll,
  admintreatmentsUpdate,
  packageDelete,
  PackageDetailsUpdate,
} from "../../Service/Services";

const TreatmentsPage = () => {
  const [showeditPopup, setShoweditPopup] = useState(false);
  const [open, setOpen] = useState(null);
  const [verify, setVerify] = useState(false);
  const [editid, setEditid] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [treatmentsData, settreatmentsData] = useState([]);
  const [hospitalTreatments,setHospitalTreatments] = useState([]);
  const [hospital,setHospital] = useState("");
  const [doctors,setDoctors] = useState([]);
  // const [Dummy, setDummy] = useState("Not Filled");

  const [form, setForm] = useState({
    Name_of_clinic_hospital:"",
    Doctor_Name:"",
    Upload_Icon: "",
    Department: "",
    Treatment_Name: "",
    Procedure_Type: "",
    Purpose_of_Procedure: "",
    duration_of_procedure: {
      time: 1, // Default to 1 minute
      unit: "minutes", // Default unit
    },
    Anesthesia_Type: "",
    Risks_and_Complications: "",
    pre_procedure_preparation: "",
    post_procedure_care: "",
    Recovery_Time: "",
    estimated_cost_range: "",
    eligibility: "",
    alternative_treatments: "",
    hospital_stay: {
      hospital_Name: "",
      time: 1, // Default to 1 hour
      unit: "hours", // Default unit
    },
    technology_used: "",
    success_rate: {
      who_much: "", // Consider using a number here
      // max_days: 100,
      // Minimum_days: 1,
    },
    follow_up_care: "",
    possible_side_effects: "",
    contraindications: "",
    recovery_milestones: {
      recovery_milestone: "",
      min_days: 1,
      max_days: 10,
    },
    doctorId:doctors[0]?._id,
    insurance_coverage: "",
    package: "",
    minprice: 0, // Ensure validation for non-negative numbers
    maxprice: 0, // Ensure validation for non-negative numbers
  });
  
  console.log(form);
  console.log(treatmentsData);
  
  //doctor array
  const handlegetDoctors = async () => {
    const responseJson = await getRequests();
    if (responseJson?.data.status) {
      setDoctors(responseJson.data.data.requests);
      console.log(responseJson.data.data.requests);
      setHospital(responseJson.data.data.requests[0].hospitalname);
    }
  };

  // Fetch all departments
  const Departmentget = async () => {
    try {
      const response = await adminDepartmentgetAll();
      const departmentData = response.data?.Data;
      console.log(departmentData);
      setDepartmentData(departmentData);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error("Failed to get department.");
      }
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "An error occurred."
      );
      console.error("Error while fetching departments:", error);
    }
  };

  // Initial fetch of departments
  const admintreatmentsget = async () => {
    try {
      const response = await admintreatmentsgetAll();
      const treatmentsData = response.data?.data || []; // Use 'data' key and default to an empty array
      settreatmentsData(treatmentsData);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error("Failed to get treatments.");
      }
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "An error occurred."
      );
      console.error("Error while fetching treatments:", error);
    }
  };

  useEffect(() => {
    // handlegetDoctors();
    admintreatmentsget();
    Departmentget();
  }, []);
  useEffect(()=>{
    getPackages();
  },[]);
  

  // Handle profile field changes
  const forProfileChange = (event) => {
    const { name, value } = event.target;

    // If the name contains a dot, we need to handle nested state
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setForm((prevForm) => ({
        ...prevForm,
        [parent]: {
          ...prevForm[parent],
          [child]: value,
        },
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    }
  };

  // Handle form submission
  const forSubmit = async (event) => {
    event.preventDefault(); // Prevents default form submission
  
    // Validate required form inputs
    if (!form.Upload_Icon || !form.Department || !form.Treatment_Name) {
      toast.error("Please fill in all required fields.");
      return; // Exit if validation fails
    }
  
    try {
      let response;
  
      // Check if editing or creating a treatment
      if (editid) {
        // Update existing treatment
        response = await admintreatmentsUpdate({
          _id: editid,
          ...form, // Spread operator to include all form fields
          Status: "Filled", // Sets status to "Filled"
        });
      } else {
        // Create a new treatment
        response = await admintreatmentscreate(form); // Pass form data directly
      }
  
      // Check response status for success
      if (response && response.status === 200) {
        toast.success(response.data.message);
        Departmentget(); // Refresh department list
        admintreatmentsget(); // Refresh treatment list
        setShoweditPopup(false);
  
        // Clear form after successful submission
        setForm({ Upload_Icon: "", Department: "", Treatment_Name: "" });
        setEditid(null); // Reset edit ID after update
      } else {
        // Display failure message if status is not 200
        toast.error(response?.data?.message || "Failed to save treatments.");
      }
    } catch (error) {
      // Improved error handling with detailed error message
      const errorMessage = error.response
        ? error.response.data.message
        : "An unexpected error occurred.";
      toast.error(errorMessage);
      console.error("Error while submitting:", error);
    }
  
    console.log(form); // Debug log for form data
  };
  

  // Handle edit action
  const handleEditTreatment = (id) => {
    const treatmentToEdit = hospitalTreatments.find((d) => d._id === id);

    if (treatmentToEdit) {
      // Ensure treatmentToEdit exists
      setForm({
        Name_of_clinic_hospital: treatmentToEdit.Name_of_clinic_hospital,
        Doctor_Name:treatmentToEdit.Doctor_Name,
        Upload_Icon: treatmentToEdit.Upload_Icon,
        Department: treatmentToEdit.Department,
        Treatment_Name: treatmentToEdit.Treatment_Name,
        Procedure_Type: treatmentToEdit.Procedure_Type,
        Purpose_of_Procedure: treatmentToEdit.Purpose_of_Procedure,
        Anesthesia_Type: treatmentToEdit.Anesthesia_Type,
        Recovery_Time: treatmentToEdit.Recovery_Time,
        Risks_and_Complications: treatmentToEdit.Risks_and_Complications,
        pre_procedure_preparation: treatmentToEdit.pre_procedure_preparation,
        post_procedure_care: treatmentToEdit.post_procedure_care,
        estimated_cost_range: treatmentToEdit.estimated_cost_range,
        eligibility: treatmentToEdit.eligibility,
        alternative_treatments: treatmentToEdit.alternative_treatments,
        technology_used: treatmentToEdit.technology_used,
        follow_up_care: treatmentToEdit.follow_up_care,
        possible_side_effects: treatmentToEdit.possible_side_effects,
        contraindications: treatmentToEdit.contraindications,
        insurance_coverage: treatmentToEdit.insurance_coverage,
        package: treatmentToEdit.package,
        minprice: treatmentToEdit.minprice,
        maxprice: treatmentToEdit.maxprice,
        duration_of_procedure: {
          time: treatmentToEdit.duration_of_procedure?.time || "", // Provide a default value if undefined
          unit: treatmentToEdit.duration_of_procedure?.unit || "", // Provide a default value if undefined
        },
        hospital_stay: {
          hospital_Name: treatmentToEdit.hospital_stay?.hospital_Name || "", // Provide a default value if undefined
          time: treatmentToEdit.hospital_stay?.time || "", // Provide a default value if undefined
          unit: treatmentToEdit.hospital_stay?.unit || "", // Provide a default value if undefined
        },
        success_rate: {
          who_much: treatmentToEdit.success_rate?.who_much || "", // Provide a default value if undefined
          max_days: treatmentToEdit.success_rate?.max_days || "", // Provide a default value if undefined
          Minimum_days: treatmentToEdit.success_rate?.Minimum_days || "", // Provide a default value if undefined
        },
        recovery_milestones: {
          recovery_milestone:
            treatmentToEdit.recovery_milestones?.recovery_milestone || "", // Provide a default value if undefined
          min_days: treatmentToEdit.recovery_milestones?.min_days || "", // Provide a default value if undefined
          max_days: treatmentToEdit.recovery_milestones?.max_days || "", // Provide a default value if undefined
        },
        Status: treatmentToEdit.Status,
      });
      setEditid(id); // Save the ID of the item being edited
      // togglePopup(); // Open the edit popup/modal
    } else {
      console.error("Treatment not found with the provided ID");
    }
  };

  // Handle delete action
  const handleDeleteUser = async (id) => {
    if (!id) return;
    try {
      const response = await packageDelete(id);
      console.log(hospitalTreatments);
      if (response.status === 200) {
        toast.success(response.data.message);  //67331b72616396762228106c  67331b72616396762228106c
        Departmentget(); // Refresh department list
        admintreatmentsget(); // Refresh treatment list
        getPackages();
      } else {
        toast.error("Failed to delete treatment.");
      }
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "An error occurred."
      );
      console.error("Error while deleting treatment:", error);
    }
  };

  // Handle menu actions
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setEditid(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const autoFillData = (e,c=1) => {
    forProfileChange(e);
    console.log(form.Department,form.Treatment_Name);
    if(form.Department || form.Treatment_Name){
      forProfileChange(e);
    }
    setTimeout(() => {
      console.log(form.Department,form.Treatment_Name);
      if (!form.Department || !form.Treatment_Name) {
        return;
      }
      for (let i = 0; i < treatmentsData.length; i++) {
        if (treatmentsData[i].Status === "Filled") {
          if (
            treatmentsData[i].Department === form.Department &&
            treatmentsData[i].Treatment_Name === form.Treatment_Name
          ) {
            const {
              Upload_Icon,
              Procedure_Type,
              Purpose_of_Procedure,
              duration_of_procedure,
              Anesthesia_Type,
              Risks_and_Complications,
              pre_procedure_preparation,
              post_procedure_care,
              Recovery_Time,
              estimated_cost_range,
              eligibility,
              alternative_treatments,
              hospital_stay,
              technology_used,
              success_rate,
              follow_up_care,
              possible_side_effects,
              contraindications,
              recovery_milestones,
              insurance_coverage,
              minprice,
              maxprice
            } = treatmentsData[i];
      
            // Update only the specified fields in form
            setForm((prev) => ({
              ...prev,
              Upload_Icon,
              Department:prev.Department,
              Treatment_Name:prev.Treatment_Name,
              Procedure_Type,
              Purpose_of_Procedure,
              duration_of_procedure,
              Anesthesia_Type,
              Risks_and_Complications,
              pre_procedure_preparation,
              post_procedure_care,
              Recovery_Time,
              estimated_cost_range,
              eligibility,
              alternative_treatments,
              // hospital_stay,
              technology_used,
              success_rate,
              follow_up_care,
              possible_side_effects,
              contraindications,
              // recovery_milestones,
              insurance_coverage,
              minprice,
              maxprice
            }));
            console.log(form);
            
            if(c===0){
              return
            }
            autoFillData(e,0)
            return;
          }
        }
      }
    }, 3000);    
    
  };

  const getPackages = async()=>{
    try {
      const res = await getAllPackages();
      console.log("res",res.data.data);
      const temp = res.data.data;
      const filtered = temp.filter((i)=>i.Status==="verified" || i.verified==="True");
      console.log(filtered,temp);
      
      setHospitalTreatments(filtered);
    } catch (error) {
      console.log(error);
    }
  }

  const clickedVerify = async () => {
    const {
      Upload_Icon,
      Department,
      Treatment_Name,
      Name_of_clinic_hospital,
    } = form;
  
    if (!Upload_Icon || !Department || !Treatment_Name || !Name_of_clinic_hospital) {
      toast.error("All fields are required!");
      return;
    }
  
    try {
      const data = {
        ...form,
        Status: "verified",
        verify:"True",
        estimated_cost_range: "Rupees",
      };
      
      const response = await PackageDetailsUpdate(data);
      console.log("response", response);
      // setHospital(hospital);
      if (response?.data) {
        toast.success("Treatment verify successfully!");
      } else {
        toast.error("Failed to verify Treatment Package.");
      }
      setShoweditPopup(false);
    } catch (error) {
      toast.error("An error occurred while verifying the package.");
      console.error(error);
    }
  };

  const clickedSave = async () => {
    const {
      Upload_Icon,
      Department,
      Treatment_Name,
      Name_of_clinic_hospital,
    } = form;
  
    if (!Upload_Icon || !Department || !Treatment_Name || !Name_of_clinic_hospital) {
      toast.error("All fields are required!");
      return;
    }
  
    try {
      const data = {
        ...form,
        Status: "verified",
        verify:"True",
        _id:editid,
        estimated_cost_range: "Rupees",
      };
      
      const response = await PackageDetailsUpdate(data);
      console.log("response", response);
      // setHospital(hospital);
      if (response?.data) {
        toast.success("Treatment verify successfully!");
      } else {
        toast.error("Failed to verify Treatment Package.");
      }
      setShoweditPopup(false);
    } catch (error) {
      toast.error("An error occurred while verifying the package.");
      console.error(error);
    }
  };
  
  return (
    <React.Fragment>
      <ThemeProvider
        theme={createTheme({
          /* Customize your theme here */
        })}
      >
        <PackagesNav />
        <Container>
          {/* Header */}
          
          <span>
            Search for Keyword
          </span>
          <Stack
            direction="row"
            alignItems="center"
            gap={4}
            mb={5}
            
          >
            <div>
                <input className="border rounded h-8" type="text"/>
            </div>
            <button className="border rounded w-32 h-8 bg-[#1da1f2] text-white">
                Search
            </button>
          </Stack>

          {/* Department List */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>ICON</TableCell>
                  <TableCell>TREATMENT NAME</TableCell>
                  <TableCell>HOSPITAL NAME</TableCell>
                  <TableCell>DR NAME</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hospitalTreatments.length > 0 ? (
                  hospitalTreatments.map((treatments) => (
                    <TableRow key={treatments._id}>
                      <TableCell>{treatments._id}</TableCell>
                      <TableCell>
                        <img
                          src={
                            treatments.Upload_Icon &&
                            treatments.Upload_Icon.startsWith("http")
                              ? treatments.Upload_Icon
                              : treatments.Upload_Icon
                              ? `${aws_url}/${treatments.Upload_Icon}` //${treatments.Upload_Icon}
                              : "path/to/default/image.jpg" 
                          }
                          alt={treatments.Upload_Icon || "Default Alt Text"}
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                          }}
                        />
                      </TableCell>
                      <TableCell>{treatments.Treatment_Name}</TableCell>
                      <TableCell>{treatments.Name_of_clinic_hospital}</TableCell>
                      <TableCell>{treatments.Doctor_Name}</TableCell>
                      <TableCell><Select
                              className="mb-3 "
                              labelId="purpose-of-procedure-label"
                              label="Purpose of Procedure"
                              required
                              fullWidth
                              value={treatments.Status}
                              name="Purpose_of_Procedure"
                              onChange={forProfileChange}
                            >
                              <MenuItem value={`verified`}>
                                verified
                              </MenuItem>
                              <MenuItem value={`Active`}>
                                Active
                              </MenuItem>
                              <MenuItem value={`InActive`}>
                                InActive
                              </MenuItem>
                            </Select>
                            </TableCell>
                      <TableCell>
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={(e) => handleOpenMenu(e, treatments._id)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No treatments found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>


          {/* Popover Menu for Edit/Delete */}
          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
          >
              
            <MenuItem
              onClick={() => {
                handleEditTreatment(editid);
                handleCloseMenu();
                setShoweditPopup(true);
              }}
            >
              <EditIcon />
              Edit
            </MenuItem>

            <MenuItem
              sx={{ color: "error.main" }}
              onClick={() => {
                handleDeleteUser(editid);
                handleCloseMenu();
              }}
            >
              <DeleteIcon />
              Delete
            </MenuItem>
          </Popover>

          {showeditPopup && (
            <form onSubmit={forSubmit}>
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white shadow-lg rounded-lg p-6 w-[900px] max-h-[90vh] overflow-y-auto z-50">
                  <Container>
                    <Stack spacing={4}>
                      {/* Upload Icon Section */}
                      <Paper elevation={3} sx={{ p: 3 }}>
                      <Typography variant="h6" gutterBottom>
                          plan
                        </Typography>
                        <Box mx={2} my={2}>
                        <FormControl fullWidth>
                            <InputLabel id="name-of-clinic">
                            Name of Hospital/Clinic
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="name-of-clinic"
                              label="Name of Hospital/Clinic"
                              required
                              fullWidth
                              value={form.Name_of_clinic_hospital}
                              name="Name_of_clinic_hospital"
                              onChange={forProfileChange}
                            >
                             
                                    <MenuItem value={`Indian Physio Care`}>
                                {"Indian Physio Care"}
                              </MenuItem>
                              
                            </Select>
                          </FormControl>
                       
                          <FormControl fullWidth>
                            <InputLabel id="name-doctor">
                            Doctor Name
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="name-doctor"
                              label="Doctor Name"
                              required
                              fullWidth
                              value={form.Doctor_Name}
                              name="Doctor_Name"
                              onChange={forProfileChange}
                            >
                              {/* {
                                doctors.map((doc)=>{
                                  return(
                                    <MenuItem value={`${doc.doctorname}`} key={`${doc.doctorname}`}>
                                {doc.doctorname}
                              </MenuItem>
                                  )
                                })
                              } */}
                              <MenuItem value={`Karthikeyan  MD Community Medicine`}>
                                {"Karthikeyan  MD Community Medicine"}
                              </MenuItem>
                            </Select>
                          </FormControl>
                          </Box>
                      </Paper>

                      {/* Form Fields */}
                      <Paper elevation={3} className="p-3"> 
                        <Typography variant="h6" gutterBottom> 
                          General Information 
                        </Typography> 
                        <Box mx={2} my={2}>
                        <FormControl fullWidth>
                            <InputLabel id="name-of-department">
                            Department 
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="name-of-department"
                              label="Department"
                              required
                              fullWidth
                              value={form.Department}
                              name="Department"
                              onChange={autoFillData}
                            >
                             {
                                treatmentsData.map((doc)=>{
                                  if(doc.Status==="Filled"){
                                  return(
                                    <MenuItem value={`${doc.Department}`} key={`${doc.Department}`}>
                                {doc.Department}
                              </MenuItem>
                                  )
                                }else{
                                  return null
                                }
                                })
                              }
                              
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="name-of-treatment">
                            Treatment 
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="name-of-treatment"
                              label="Treatment Name"
                              required
                              fullWidth
                              value={form.Treatment_Name}
                              name="Treatment_Name"
                              onChange={autoFillData}
                            >
                             
                             {
                                treatmentsData.map((doc)=>{
                                  if(doc.Status==="Filled"){
                                  return(
                                    <MenuItem value={`${doc.Treatment_Name}`} key={`${doc.Treatment_Name}`}>
                                {doc.Treatment_Name}
                              </MenuItem>
                                  )
                                }else{
                                  return null
                                }
                                })
                              }
                              
                            </Select>
                          </FormControl>
                          <Typography variant="h6" gutterBottom>
                          Icon
                        </Typography>
                      <FormControl fullWidth component={Stack} spacing={2}>
                          <img
                            src={
                              form.Upload_Icon
                                ? `${aws_url}/${form.Upload_Icon}`
                                : ``
                            }
                            alt="Upload_Icon"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "10%",
                            }}
                          />
                        </FormControl>
                          </Box>
                        <Box mx={2} my={2}>
                          <FormControl fullWidth>
                            <InputLabel id="purpose-of-procedure-label">
                              Purpose of Procedure
                            </InputLabel>
                            <Select
                              className="mb-3 "
                              labelId="purpose-of-procedure-label"
                              label="Purpose of Procedure"
                              required
                              fullWidth
                              value={form.Purpose_of_Procedure}
                              name="Purpose_of_Procedure"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Pain relief">
                                Pain relief
                              </MenuItem>
                              <MenuItem value="Mobility restoration">
                                Mobility restoration
                              </MenuItem>
                              <MenuItem value="Tumor removal">
                                Tumor removal
                              </MenuItem>
                              <MenuItem value="Vision correction">
                                Vision correction
                              </MenuItem>
                              <MenuItem value="Infection removal">
                                Infection removal
                              </MenuItem>
                              <MenuItem value="Heart function restoration">
                                Heart function restoration
                              </MenuItem>
                              <MenuItem value="Cosmetic enhancement">
                                Cosmetic enhancement
                              </MenuItem>
                              <MenuItem value="Organ transplant">
                                Organ transplant
                              </MenuItem>
                              <MenuItem value="Symptom management">
                                Symptom management
                              </MenuItem>
                              <MenuItem value="Tissue repair or reconstruction">
                                Tissue repair or reconstruction
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="anesthesia-type-label">
                              Anesthesia Type
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="anesthesia-type-label"
                              label="Anesthesia Type"
                              required
                              fullWidth
                              value={form.Anesthesia_Type}
                              name="Anesthesia_Type"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="General anesthesia">
                                General anesthesia
                              </MenuItem>
                              <MenuItem value="Spinal anesthesia">
                                Spinal anesthesia
                              </MenuItem>
                              <MenuItem value="Local anesthesia">
                                Local anesthesia
                              </MenuItem>
                              <MenuItem value="Regional anesthesia">
                                Regional anesthesia
                              </MenuItem>
                              <MenuItem value="Conscious sedation">
                                Conscious sedation
                              </MenuItem>
                              <MenuItem value="Epidural anesthesia">
                                Epidural anesthesia
                              </MenuItem>
                              <MenuItem value="Nerve block anesthesia">
                                Nerve block anesthesia
                              </MenuItem>
                              <MenuItem value="Intravenous (IV) sedation">
                                Intravenous (IV) sedation
                              </MenuItem>
                              <MenuItem value="Topical anesthesia">
                                Topical anesthesia (e.g., for eye surgeries)
                              </MenuItem>
                              <MenuItem value="No anesthesia">
                                No anesthesia (for some diagnostic procedures)
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="recovery-time-label">
                              Average Recovery Time
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="recovery-time-label"
                              label="Average Recovery Time"
                              required
                              fullWidth
                              value={form.Recovery_Time}
                              name="Recovery_Time"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="1-2 days">1-2 days</MenuItem>
                              <MenuItem value="1-2 weeks">1-2 weeks</MenuItem>
                              <MenuItem value="4-6 weeks">4-6 weeks</MenuItem>
                              <MenuItem value="2-3 months">2-3 months</MenuItem>
                              <MenuItem value="3-6 months">3-6 months</MenuItem>
                              <MenuItem value="6-12 months (for major procedures)">
                                6-12 months (for major procedures)
                              </MenuItem>
                              <MenuItem value="Same day (for minor outpatient procedures)">
                                Same day (for minor outpatient procedures)
                              </MenuItem>
                              <MenuItem value="3-5 days (for minimally invasive surgeries)">
                                3-5 days (for minimally invasive surgeries)
                              </MenuItem>
                              <MenuItem value="Ongoing (e.g., chronic conditions management)">
                                Ongoing (e.g., chronic conditions management)
                              </MenuItem>
                              <MenuItem value="Varies depending on individual factors (e.g., age, health)">
                                Varies depending on individual factors (e.g.,
                                age, health)
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="eligibility-label">
                              Eligibility
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="eligibility-label"
                              label="Eligibility"
                              required
                              fullWidth
                              value={form.eligibility}
                              name="eligibility"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Patients with chronic pain">
                                Patients with chronic pain
                              </MenuItem>
                              <MenuItem value="Individuals with limited mobility">
                                Individuals with limited mobility
                              </MenuItem>
                              <MenuItem value="Candidates for cosmetic improvements">
                                Candidates for cosmetic improvements
                              </MenuItem>
                              <MenuItem value="Patients with heart conditions">
                                Patients with heart conditions
                              </MenuItem>
                              <MenuItem value="Those with severe arthritis">
                                Those with severe arthritis
                              </MenuItem>
                              <MenuItem value="Individuals with damaged organs">
                                Individuals with damaged organs
                              </MenuItem>
                              <MenuItem value="Patients with tumors">
                                Patients with tumors
                              </MenuItem>
                              <MenuItem value="People experiencing significant vision impairment">
                                People experiencing significant vision
                                impairment
                              </MenuItem>
                              <MenuItem value="Healthy candidates for organ transplants">
                                Healthy candidates for organ transplants
                              </MenuItem>
                              <MenuItem value="Patients whose conditions are unresponsive to other treatments">
                                Patients whose conditions are unresponsive to
                                other treatments
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="alternative-treatments-label">
                              Alternative Treatments
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="alternative-treatments-label"
                              label="Alternative Treatments"
                              required
                              fullWidth
                              value={form.alternative_treatments}
                              name="alternative_treatments"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Medication">Medication</MenuItem>
                              <MenuItem value="Physical therapy">
                                Physical therapy
                              </MenuItem>
                              <MenuItem value="Injections (e.g., corticosteroids)">
                                Injections (e.g., corticosteroids)
                              </MenuItem>
                              <MenuItem value="Lifestyle modifications (e.g., weight loss, diet changes)">
                                Lifestyle modifications (e.g., weight loss, diet
                                changes)
                              </MenuItem>
                              <MenuItem value="Orthotics or braces">
                                Orthotics or braces
                              </MenuItem>
                              <MenuItem value="Non-surgical procedures (e.g., laser treatments)">
                                Non-surgical procedures (e.g., laser treatments)
                              </MenuItem>
                              <MenuItem value="Counseling or psychological support">
                                Counseling or psychological support
                              </MenuItem>
                              <MenuItem value="Acupuncture">
                                Acupuncture
                              </MenuItem>
                              <MenuItem value="Radiofrequency ablation">
                                Radiofrequency ablation
                              </MenuItem>
                              <MenuItem value="Watchful waiting or observation">
                                Watchful waiting or observation
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="technology-used-label">
                              Technology Used
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="technology-used-label"
                              label="Technology Used"
                              required
                              fullWidth
                              value={form.technology_used}
                              name="technology_used"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Robotic-assisted surgery">
                                Robotic-assisted surgery
                              </MenuItem>
                              <MenuItem value="Laparoscopy">
                                Laparoscopy
                              </MenuItem>
                              <MenuItem value="Laser surgery">
                                Laser surgery
                              </MenuItem>
                              <MenuItem value="Endoscopy">Endoscopy</MenuItem>
                              <MenuItem value="Imaging guidance (e.g., MRI, CT)">
                                Imaging guidance (e.g., MRI, CT)
                              </MenuItem>
                              <MenuItem value="3D printing (e.g., custom implants)">
                                3D printing (e.g., custom implants)
                              </MenuItem>
                              <MenuItem value="Virtual reality for surgical planning">
                                Virtual reality for surgical planning
                              </MenuItem>
                              <MenuItem value="Cryotherapy">
                                Cryotherapy
                              </MenuItem>
                              <MenuItem value="Radiofrequency ablation">
                                Radiofrequency ablation
                              </MenuItem>
                              <MenuItem value="Genetic testing for precision treatments">
                                Genetic testing for precision treatments
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="possible-side-effects-label">
                              Possible Side Effects
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="possible-side-effects-label"
                              label="Possible Side Effects"
                              required
                              fullWidth
                              value={form.possible_side_effects}
                              name="possible_side_effects"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Temporary swelling">
                                Temporary swelling
                              </MenuItem>
                              <MenuItem value="Bruising">Bruising</MenuItem>
                              <MenuItem value="Nausea">Nausea</MenuItem>
                              <MenuItem value="Pain at the surgical site">
                                Pain at the surgical site
                              </MenuItem>
                              <MenuItem value="Fatigue">Fatigue</MenuItem>
                              <MenuItem value="Dizziness">Dizziness</MenuItem>
                              <MenuItem value="Scarring">Scarring</MenuItem>
                              <MenuItem value="Allergic reactions to medications">
                                Allergic reactions to medications
                              </MenuItem>
                              <MenuItem value="Muscle weakness">
                                Muscle weakness
                              </MenuItem>
                              <MenuItem value="Blood pressure fluctuations">
                                Blood pressure fluctuations
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="contraindications-label">
                              Contraindications
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="contraindications-label"
                              label="Contraindications"
                              required
                              fullWidth
                              value={form.contraindications}
                              name="contraindications"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Severe heart conditions">
                                Severe heart conditions
                              </MenuItem>
                              <MenuItem value="Uncontrolled diabetes">
                                Uncontrolled diabetes
                              </MenuItem>
                              <MenuItem value="Active infections">
                                Active infections
                              </MenuItem>
                              <MenuItem value="Bleeding disorders">
                                Bleeding disorders
                              </MenuItem>
                              <MenuItem value="Pregnancy">Pregnancy</MenuItem>
                              <MenuItem value="Allergies to anesthesia">
                                Allergies to anesthesia
                              </MenuItem>
                              <MenuItem value="Severe obesity (in some procedures)">
                                Severe obesity (in some procedures)
                              </MenuItem>
                              <MenuItem value="Poor kidney or liver function">
                                Poor kidney or liver function
                              </MenuItem>
                              <MenuItem value="Immune system disorders">
                                Immune system disorders
                              </MenuItem>
                              <MenuItem value="Chronic respiratory conditions">
                                Chronic respiratory conditions
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="insurance-coverage-label">
                              Insurance Coverage
                            </InputLabel>
                            <Select
                              className="mb-3"
                              labelId="insurance-coverage-label"
                              label="Insurance Coverage"
                              required
                              fullWidth
                              value={form.insurance_coverage}
                              name="insurance_coverage"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Covered by most health insurance plans">
                                Covered by most health insurance plans
                              </MenuItem>
                              <MenuItem value="Prior authorization required">
                                Prior authorization required
                              </MenuItem>
                              <MenuItem value="Out-of-pocket costs for co-pays">
                                Out-of-pocket costs for co-pays
                              </MenuItem>
                              <MenuItem value="Government health coverage (e.g., Medicare, Medicaid)">
                                Government health coverage (e.g., Medicare,
                                Medicaid)
                              </MenuItem>
                              <MenuItem value="High-deductible health plans (HDHP) considerations">
                                High-deductible health plans (HDHP)
                                considerations
                              </MenuItem>
                              <MenuItem value="No coverage for cosmetic procedures">
                                No coverage for cosmetic procedures
                              </MenuItem>
                              <MenuItem value="Partial coverage based on procedure type">
                                Partial coverage based on procedure type
                              </MenuItem>
                              <MenuItem value="International medical tourism packages">
                                International medical tourism packages
                              </MenuItem>
                              <MenuItem value="Employer-sponsored health plans">
                                Employer-sponsored health plans
                              </MenuItem>
                              <MenuItem value="Supplemental insurance options">
                                Supplemental insurance options
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="follow-up-care-label">
                              Follow Up Care
                            </InputLabel>
                            <Select
                              labelId="follow-up-care-label"
                              label="Follow Up Care"
                              required
                              fullWidth
                              value={form.follow_up_care || ""} // Default value if undefined
                              name="follow_up_care"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Physical therapy sessions">
                                Physical therapy sessions
                              </MenuItem>
                              <MenuItem value="Home exercises">
                                Home exercises
                              </MenuItem>
                              <MenuItem value="Regular check-ups">
                                Regular check-ups
                              </MenuItem>
                              <MenuItem value="Imaging tests (e.g., X-rays, MRI)">
                                Imaging tests (e.g., X-rays, MRI)
                              </MenuItem>
                              <MenuItem value="Wound monitoring">
                                Wound monitoring
                              </MenuItem>
                              <MenuItem value="Removal of sutures or staples">
                                Removal of sutures or staples
                              </MenuItem>
                              <MenuItem value="Adjustments in medications">
                                Adjustments in medications
                              </MenuItem>
                              <MenuItem value="Occupational therapy (if needed)">
                                Occupational therapy (if needed)
                              </MenuItem>
                              <MenuItem value="Cardiac rehabilitation (for heart procedures)">
                                Cardiac rehabilitation (for heart procedures)
                              </MenuItem>
                              <MenuItem value="Psychosocial support">
                                Psychosocial support
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Paper>

                      {/* Estimated Cost Range Field with Currency Options */}
                      <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                          Success Rate
                        </Typography>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                        >
                          {/* Success Rate Dropdown */}
                          <FormControl fullWidth>
                            <InputLabel id="success-rate-label">
                              Success Rate (%)
                            </InputLabel>
                            <Select
                              labelId="success-rate-label"
                              label="Success Rate (%)"
                              required
                              fullWidth
                              value={form.success_rate["who_much"] || ""}
                              name="success_rate.who_much"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="80-85">80% - 85%</MenuItem>
                              <MenuItem value="85-90">85% - 90%</MenuItem>
                              <MenuItem value="90-95">90% - 95%</MenuItem>
                              <MenuItem value="95-99">95% - 99%</MenuItem>
                              <MenuItem value="varies">
                                Varies by patient’s condition and procedure
                              </MenuItem>
                              <MenuItem value="high_success">
                                High success rate with experienced surgeons
                              </MenuItem>
                              <MenuItem value="post_surgical">
                                Success influenced by post-surgical care
                                adherence
                              </MenuItem>
                              <MenuItem value="long_term">
                                Long-term success rates (e.g., 10-year outcomes)
                              </MenuItem>
                              <MenuItem value="minimally_invasive">
                                Higher success with minimally invasive
                                techniques
                              </MenuItem>
                              <MenuItem value="coexisting_conditions">
                                Success may depend on coexisting medical
                                conditions
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                      </Paper>

                      {/* Duration of Procedure Section */}
                      <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                          Duration of Procedure
                        </Typography>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                        >
                          <FormControl fullWidth>
                            <TextField
                              label="Duration of Procedure (minutes)"
                              type="number"
                              required
                              fullWidth
                              value={form.duration_of_procedure.time}
                              autoComplete="off"
                              name="duration_of_procedure.time"
                              onChange={forProfileChange}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="duration-unit-label">
                              Duration of Procedure (unit)
                            </InputLabel>
                            <Select
                              labelId="duration-unit-label"
                              label="Duration of Procedure (unit)"
                              required
                              fullWidth
                              value={form.duration_of_procedure.unit}
                              name="duration_of_procedure.unit"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="minutes">Minutes</MenuItem>
                              <MenuItem value="hours">Hours</MenuItem>
                              <MenuItem value="days">Days</MenuItem>
                              <MenuItem value="weeks">Weeks</MenuItem>
                              <MenuItem value="months">Months</MenuItem>
                              <MenuItem value="years">Years</MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                      </Paper>

                      {/* Hospital Stay Section */}
                      <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                          Hospital Stay
                        </Typography>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                        >
                          {/* <FormControl fullWidth>
                            <InputLabel id="hospital-stay-duration-label">
                              Hospital Stay 
                            </InputLabel>
                            <Select
                              labelId="hospital-stay-duration-label"
                              label="Hospital Stay "
                              required
                              fullWidth
                              value={form.hospital_stay.hospital_Name || ""} // Default value if undefined
                              name="hospital_stay.hospital_Name"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Outpatient (same-day discharge)">
                                Outpatient (same-day discharge)
                              </MenuItem>
                              <MenuItem value="1 day">1 day</MenuItem>
                              <MenuItem value="2 days">2 days</MenuItem>
                              <MenuItem value="3 days">3 days</MenuItem>
                              <MenuItem value="4-7 days">4-7 days</MenuItem>
                              <MenuItem value="1-2 weeks (for major surgeries)">
                                1-2 weeks (for major surgeries)
                              </MenuItem>
                              <MenuItem value="ICU stay (if required)">
                                ICU stay (if required)
                              </MenuItem>
                              <MenuItem value="Rehabilitation facility (if needed for recovery)">
                                Rehabilitation facility (if needed for recovery)
                              </MenuItem>
                              <MenuItem value="Extended stay for complications">
                                Extended stay for complications
                              </MenuItem>
                              <MenuItem value="Home care after early discharge">
                                Home care after early discharge
                              </MenuItem>
                            </Select>
                          </FormControl> */}
                          <FormControl fullWidth>
                            <TextField
                              label="Hospital Stay Duration (hours)"
                              type="number"
                              required
                              fullWidth
                              value={form.hospital_stay.time}
                              autoComplete="off"
                              name="hospital_stay.time"
                              onChange={forProfileChange}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="hospital-stay-unit-label">
                              Hospital Stay Duration (unit)
                            </InputLabel>
                            <Select
                              labelId="hospital-stay-unit-label"
                              label="Hospital Stay Duration (unit)"
                              required
                              fullWidth
                              value={form.hospital_stay.unit}
                              name="hospital_stay.unit"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="hours">Hours</MenuItem>
                              <MenuItem value="days">Days</MenuItem>
                              <MenuItem value="weeks">Weeks</MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                      </Paper>

                      {/* Success Rate Section */}
                      {/* <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                          Success Rate
                        </Typography>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                        >
                          {[
                            {
                              label: "Success Rate (%)",
                              name: "success_rate.who_much",
                            },
                            {
                              label: "Minimum Days",
                              name: "success_rate.Minimum_days",
                            },
                            {
                              label: "Maximum Days",
                              name: "success_rate.max_days",
                            },
                          ].map((field) => (
                            <FormControl fullWidth key={field.name}>
                              <TextField
                                label={field.label}
                                type="number"
                                fullWidth
                                value={
                                  form.success_rate[field.name.split(".")[1]]
                                }
                                autoComplete="off"
                                name={field.name}
                                onChange={forProfileChange}
                              />
                            </FormControl>
                          ))}
                        </Stack>
                      </Paper> */}

                      {/* Recovery Milestones Section */}
                      <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                          Recovery Milestones
                        </Typography>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                        >
                          <FormControl fullWidth>
                            <InputLabel id="recovery-milestones-label">
                              Recovery Milestone
                            </InputLabel>
                            <Select
                              labelId="recovery-milestones-label"
                              label="Recovery Milestone"
                              required
                              fullWidth
                              value={
                                form.recovery_milestones.recovery_milestone ||
                                ""
                              } // Default value if undefined
                              name="recovery_milestones.recovery_milestone"
                              onChange={forProfileChange}
                            >
                              <MenuItem value="Walking without assistance in 2 weeks">
                                Walking without assistance in 2 weeks
                              </MenuItem>
                              <MenuItem value="Return to work in 6 weeks">
                                Return to work in 6 weeks
                              </MenuItem>
                              <MenuItem value="Full range of motion in 3-6 months">
                                Full range of motion in 3-6 months
                              </MenuItem>
                              <MenuItem value="Pain-free movement by 1 month">
                                Pain-free movement by 1 month
                              </MenuItem>
                              <MenuItem value="Driving within 4-6 weeks">
                                Driving within 4-6 weeks
                              </MenuItem>
                              <MenuItem value="Return to sports in 6-12 months">
                                Return to sports in 6-12 months
                              </MenuItem>
                              <MenuItem value="Normal daily activities in 3-4 weeks">
                                Normal daily activities in 3-4 weeks
                              </MenuItem>
                              <MenuItem value="Removal of surgical dressings within 10-14 days">
                                Removal of surgical dressings within 10-14 days
                              </MenuItem>
                              <MenuItem value="Discharge from physical therapy after 3-6 months">
                                Discharge from physical therapy after 3-6 months
                              </MenuItem>
                              <MenuItem value="Long-term follow-up for implant integrity (if applicable)">
                                Long-term follow-up for implant integrity (if
                                applicable)
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              label="Minimum Days to Recover"
                              type="number"
                              required
                              fullWidth
                              value={form.recovery_milestones.min_days}
                              autoComplete="off"
                              name="recovery_milestones.min_days"
                              onChange={forProfileChange}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              label="Maximum Days to Recover"
                              type="number"
                              required
                              fullWidth
                              value={form.recovery_milestones.max_days}
                              autoComplete="off"
                              name="recovery_milestones.max_days"
                              onChange={forProfileChange}
                            />
                          </FormControl>
                        </Stack>
                      </Paper>

                      {/* Submit and Close Buttons */}
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={2}
                        sx={{ mt: 4 }}
                      >
                     {verify &&   <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          onClick={clickedVerify}
                        >
                          Verify
                        </Button>}
                        {verify &&  <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          onClick={()=>{console.log("clicked")}}
                        >
                          Hold
                        </Button>}
                       {verify &&  <Button
                          variant="contained"
                          color="error"
                          onClick={() =>{ setShoweditPopup(false);}}
                        >
                          Reject
                        </Button>}
                    {!verify &&    <Button
                          variant="contained"
                          color="primary"
                          onClick={clickedSave}
                        >
                          Save
                        </Button> }
                        {!verify &&    <Button
                          variant="contained"
                          color="error"
                          onClick={() =>{ setShoweditPopup(false);}}
                        >
                          Close
                        </Button>}
                      </Stack>
                    </Stack>
                  </Container>
                </div>
              </div>
            </form>
          )}
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default TreatmentsPage;
