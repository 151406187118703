import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VerifiedIcon from '@mui/icons-material/Verified';
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import {
  CircularProgress,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  createTheme,
  ThemeProvider,
  TableHead,
  TextField,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Box,
} from "@mui/material";
import { getRequests, updateRequest } from "../../Service/Services";
import toast from "react-hot-toast";

const Requests = () => {
  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#133680",
      },
      secondary: {
        main: "#f50057",
      },
      text: {
        primary: "#000000",
      },
    },
    typography: {
      fontFamily: "Montserrat",
    },
  });




  const [d_id,setD_id] = useState("");
  const nav = useNavigate();
  const [requests, setRequests] = useState([]);
  const [searchText,setSearchText] = useState("");
  const [open,setOpen] = useState(null);
  const [editid,setEditid] = useState("");
  // const [hospitals,setHospitals] = useState([]);
  const handlegetRequests = async () => {
    const responseJson = await getRequests();
    let temp = [];
    let data = [...responseJson.data.data.requests];
    if (responseJson.data.status) {
      for(let i=0;i<data.length;i++){
        let flag = 0;
        for(let j=0;j<temp.length;j++){
          if(temp.length === 0){
            break;
          }
          if(temp[j].hospitalname === data[i].hospitalname){
            const obj = {
              doctorname:data[i].doctorname,
              _id:data[i]._id,
              doctorId:data[i].doctorId,
              status:data[i].status,
            }
            temp[j].doctors = [...temp[j].doctors,{...obj}];
            flag = 1 ;
            break
          }
        }
        if(flag !== 1){
          const  obj = {
            doctorname:data[i].doctorname,
            _id:data[i]._id,
            doctorId:data[i].doctorId,
            status:data[i].status,
          }
          temp.push({
            hospitalname:data[i].hospitalname,
            doctors:[{...obj}]
          })
        }
      }
      // setRequests(responseJson.data.data.requests);
      setRequests(temp);
      console.log(temp);
      
      console.log(responseJson.data);
    }
  };

  const handleOptions = async(status)=>{
    try {
      const res = await getRequests();
      const data = [...res.data.data.requests];
      console.log(data,editid);
      
      const request = data.filter((r)=>r._id===editid);
      console.log(request);
      const updatedData = {...request[0],status};
      console.log(updatedData);
      handleUpdateRequest(updatedData);
    } catch (error) {
      console.log(error,"while fetching requests");
      
    }
  }

  const handleUpdateRequest = async (request, status) => {
    const requestBody = { ...request };
    const responseJson = await updateRequest(request._id, requestBody);
    if (responseJson.data.status) {
      toast.success(responseJson.data.message);
    } else {
      toast.error(responseJson.data.message);
    }
    await handlegetRequests();
  };
  useEffect(() => {
    handlegetRequests();
  },[]);

  const handleOpenMenu = (event, did,id) => {
    setOpen(event.currentTarget);
    setD_id(did)
    setEditid(id);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const viewDoctorDetails = ()=>{
    const id = d_id;
    window.open(`https://healthmudraa.com/Rajamahendravaram/doctor/${id}`,"_blank").focus();
    // nav(`https://healthmudraa.com/Rajamahendravaram/doctor/${id}`)

  }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {/* <ResponsiveDiv>
          <CarouselComponent />
        </ResponsiveDiv> */}

        <Box display={"flex"} flexDirection="column" alignItems={"center"}>
        <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>HOSPITAL NAME</TableCell>
                 
                </TableRow>
              </TableHead>
              <TableBody>
                {requests.length > 0 ? (
                  requests.map((request) => (
                    <TableRow key={request._id}>
                      <TableCell>{request.hospitalname}</TableCell>
                      <TableCell>
                        <TableRow>
                        <TableCell>DOCTOR NAME</TableCell>
                        <TableCell>Status</TableCell>
                       <TableCell>Edit</TableCell>
                        </TableRow>
                        {request.doctors.map((d)=>(
                          <TableRow>
                             <TableCell>{d.doctorname}</TableCell>
                             <TableCell>{d.status === "Pending" ? "InActive" : "Active" }</TableCell>
                      
                      <TableCell>
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={(e) => handleOpenMenu(e,d.doctorId, d._id)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                          </TableRow>
                        ))}
                      </TableCell>
                     
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No treatments found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Popover */}
          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
          >
             <MenuItem
             
              onClick={() => {
                viewDoctorDetails();
                handleCloseMenu();
              }} 
            >
              <FaEye />
              view details
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOptions("Accept");
                handleCloseMenu();
              }} 
            >
              <VerifiedIcon></VerifiedIcon>
              Approve
            </MenuItem>
            <MenuItem
              sx={{ color: "error.main" }}
              onClick={() => {
                // handleDelete();
                handleOptions("Remove");
                handleCloseMenu();
              }} 
            >
              <DeleteIcon />
              Delete
            </MenuItem>
          </Popover>
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Requests;
