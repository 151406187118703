import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import { Box, List, ListItemText } from "@mui/material";
import { StyledNavItem, StyledNavItemIcon } from "./styles";

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  // Add "Verify Doctors" item to the top of the data array
  const updatedData = [
    {
      title: "Verify Doctors",
      path: "/admin/dashboard/verifydoctor",
      icon: null,
    },
    {
      title: "Departments List",
      path: "/admin/dashboard/department",
      icon: null,
    },

    {
      title: "Doctor Speciality",
      path: "/admin/dashboard/specializations",
      icon: null,
    },

    {
      title: "Doctors Language",
      path: "/admin/dashboard/language",
      icon: null,
    },

    {
      title: "Treatments list",
      path: "/admin/dashboard/treatment",
      icon: null, 
    }, 
    
     ...data,
   
    {
      title: "Treatment Packages",
      path: "/admin/dashboard/treatmentPackages/PackageRequests",
      icon: null,
    },
    
  ];

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {updatedData.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        "&.active": {
          color: "text.primary",
          bgcolor: "action.selected",
          fontWeight: "fontWeightBold",
        },
      }}
    >   
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
